import { CopyOutlined, LoadingOutlined } from '@ant-design/icons';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  Radio,
  Row,
  message,
} from 'antd';
import axios from 'axios';
import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../auth';
import faangpathService from '../../../../services/api/FaangPathService';
import ThemeColors from '../../../../theme/themeColors';
import { config, stripeCheckoutApiPath } from '../../../../utils/apiPaths';
import { handleOnKeyDown } from '../../../../utils/eventListeners';
import './PaymentsPlan.scss';
import { ReactComponent as Aitools } from './images/AiTools.svg';
import { ReactComponent as Arrow } from './images/Arrow.svg';

import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { features } from '../../Onboarding/NewOnboarding/Plan/Plans';
import styles from './PaymentsPlan.module.scss';
import { CareerflowSaleData } from '../../../../careerflowSaleData';
import { t } from 'i18next';
import mapleLeave from 'images/mapleLeave.png';
import { STRIPE_PAYMENT_SUCCESS_URL } from '../../../../constants/miscellaneous';

const { Panel } = Collapse;

const PaymentsPlanV2 = ({
  setPaymentPageVisited,
  setUpgrade,
  setShowFeatures,
}: any) => {
  const [planLoading, setPlanLoading] = React.useState(false);
  const [redeemLoading, setRedeemLoading] = React.useState(false);
  const [openRedeemVoucherModal, setOpenRedeemVoucherModal] =
    React.useState(false);
  const [voucherCode, setVoucherCode] = React.useState('');
  const [selectedPlan, setSelectedPlan] = React.useState<any>(null);
  const [paymentLoading, setPaymentLoading] = React.useState(false);
  const [pricingData, setPricingData] = React.useState<any>([]);
  const { userInfo, institute, appConfig, isMobile } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isOnboarding = searchParams.get('onboarding');
  const [viewMore, setViewMore] = React.useState(false);

  const isSaleEnabled =
    CareerflowSaleData.saleEndTimestamp > Date.now() &&
    appConfig?.features?.specialSaleModal?.enabled;

  const handleSubscribe = async ({
    priceId,
    referralId,
  }: {
    priceId: string;
    referralId?: string;
  }) => {
    setPaymentLoading(true);
    analyticsEvent('Go to stripe payments page for upgrade');
    axios
      .post(stripeCheckoutApiPath, {
        price: priceId,
        success_url: STRIPE_PAYMENT_SUCCESS_URL,
        referral_id: referralId,
      })
      .then((res) => window.open(res.data.url))
      .finally(() => setPaymentLoading(false));
  };

  const handleCopyCode = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code);

      message.success('Copied to clipboard');
    } catch (error) {
      console.log(error);
    }
  };

  const loadPricing = useCallback(async () => {
    try {
      setPlanLoading(true);
      const res = await axios.get(config.baseUrl + '/faangpath/pricing');
      setPricingData(res.data.content);
      setSelectedPlan(res.data.content.plans[0]);
    } catch (e) {
      console.log(e);
    } finally {
      setPlanLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      userInfo.userType === 'premium' &&
      window.location.pathname === '/plans'
    ) {
      navigate('/dashboard');
      setUpgrade(false);
      setShowFeatures(true);
    }
  }, [navigate, userInfo.userType, setUpgrade, setShowFeatures]);

  useEffect(() => {
    loadPricing();
  }, [loadPricing]);

  const redeemVoucherCode = () => {
    setRedeemLoading(true);
    faangpathService
      .redeemVoucherCFPremium(voucherCode)
      .then(() => {
        message.success('Redeemed Successfully.');
        setPaymentPageVisited(true);
        setUpgrade(false);
      })
      .catch((e) => {
        console.log(e);
        message.error(e.response.data.message);
      })
      .finally(() => setRedeemLoading(false));
  };

  return (
    <div
      id="payment-modal"
      className={clsx('dottedBg', 'payment-plans-v2')}
      style={{ zIndex: '10' }}
    >
      <div
        className={`${styles.subHeading}`}
        style={{
          background: !isSaleEnabled
            ? ThemeColors.themeBackgroundColor
            : '#A25400',
          padding: '1rem 2rem',
          color: 'white',
          borderRadius: '8px 8px 0 0',
          position: 'sticky',
        }}
      >
        {isSaleEnabled &&
        (userInfo.country === 'www' || userInfo.country === 'CA') ? (
          <div className={styles.subHeadingInner}>
            <div className={styles.saleUpto}>
              <img
                alt={'Maple Leaves'}
                src={mapleLeave}
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  height: '60px',
                  zIndex: '-1',
                }}
              ></img>
              <span className={styles.independenceTitle}>
                {CareerflowSaleData.eventName}{' '}
              </span>
              <span
                style={{
                  color: `#fe9e34`,
                }}
              >
                SALE
              </span>{' '}
              FLAT{' '}
              <span
                style={{
                  color: `#fe9e34`,
                }}
              >
                {CareerflowSaleData.discountPercent}%
              </span>{' '}
              ON ANNUAL PLAN
            </div>
            <div className={styles.couponCodeContainer}>
              <p className={styles.couponCodeContainerTitle}>
                {'Use this Coupon at checkout'}
              </p>
              <div className={styles.couponCodeContainerInner}>
                <h3 className={styles.couponCodeText}>
                  {CareerflowSaleData.couponCode}
                </h3>
                <Button
                  className={styles.copyButton}
                  type="default"
                  icon={<CopyOutlined />}
                  size="small"
                  onClick={() => {
                    analyticsEvent('Code copied');
                    handleCopyCode(CareerflowSaleData.couponCode);
                  }}
                ></Button>
              </div>
            </div>
            <div className={styles.onlyValidContainer}>
              <span className={styles.onlyValidText}>
                {'Offer Valid till '}
              </span>
              <span className={clsx(styles.onlyValidText, styles.white)}>
                {CareerflowSaleData.eventDateText}
              </span>
            </div>
          </div>
        ) : (
          t('UpgradeyourplanandgetAIAssistance')
        )}
      </div>
      {planLoading ? (
        <div
          style={{
            padding: '2rem',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            zIndex: '2',
          }}
        >
          <LoadingOutlined />
        </div>
      ) : (
        !planLoading &&
        pricingData?.plans?.length && (
          <div
            style={{
              padding: '2rem 3rem',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              color: ThemeColors.themeBackgroundColor,
            }}
            className="payment-plans"
          >
            <div className="payment-plans-heading">
              {'Choose your payment frequency'}
            </div>
            <div className="payment-plans-sub-heading">
              {'Instant Access, Cancel Anytime'}
            </div>
            <Row
              style={{
                height: '100%',
                justifyContent: 'space-between',
                marginTop: '2rem',
                display: 'flex',
                width: '100%',
              }}
              className="payment-plans-row"
            >
              <Col
                span={20}
                md={9}
                style={{ padding: '1.5rem 2rem', borderRadius: '8px' }}
                className="payment-card"
              >
                <div className="selected-plan-container">
                  <div className="selected-plan-name">
                    {'Careerflow Premium'}
                  </div>
                  <Aitools />
                </div>
                <div className="selected-plan-subtext-1">
                  {'Premium members are'}
                </div>
                <div className="selected-plan-subtext-2">
                  {'10X more effective in their Job Search'}
                </div>

                <Divider style={{ margin: '14px 0 !important' }} />
                <div className="payment-features-container">
                  {/* {selectedPlan?.features.map((feature: any, i) => {
                    if (i === 0) {
                      return (
                        <div className="payment-feature-first">{feature}</div>
                      );
                    }
                    return (
                      <div className="payment-feature-outer">
                        <Arrow />{' '}
                        <span className="payment-feature-inner">{feature}</span>
                      </div>
                    );
                  })} */}
                  <div className="payment-feature-first">
                    {'Everything in the free version, plus:'}
                  </div>
                  <Collapse ghost expandIcon={Arrow}>
                    {features?.paidFeatures
                      .filter((_item, index) =>
                        !viewMore && index > 3 ? false : true
                      )
                      .map((feature, i) => {
                        return (
                          <Panel
                            className="feature-item-collapse"
                            header={feature.title}
                            key={i + 1}
                          >
                            <p>{feature.description}</p>
                          </Panel>
                        );
                      })}
                  </Collapse>
                  {!viewMore ? (
                    <Button
                      style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                      }}
                      type="link"
                      onClick={() => {
                        setViewMore(true);
                        if (isOnboarding) {
                          analyticsEvent(
                            'Onboarding  - Pricing intro - view more',
                            'Onboarding'
                          );
                        }
                      }}
                    >
                      {'View more'}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                      }}
                      type="link"
                      onClick={() => setViewMore(false)}
                    >
                      {'View less'}
                    </Button>
                  )}
                </div>
              </Col>
              <Col
                span={20}
                md={14}
                style={{ padding: '1.5rem 2rem', borderRadius: '8px' }}
                className="payment-card"
              >
                <div style={{ fontSize: '14px', fontWeight: '600' }}>
                  <span style={{ color: '#3EBB5A' }}>&#10003;</span>{' '}
                  {pricingData?.plansSectionTitle}
                </div>
                <Radio.Group
                  value={selectedPlan?.name}
                  style={{ width: '100%' }}
                >
                  {pricingData?.plans.map((plan: any) => (
                    <div
                      onClick={() => {
                        setSelectedPlan(plan);
                        if (isOnboarding) {
                          analyticsEvent(plan?.analytics, 'Onboarding', {
                            end_timestamp: Date.now(),
                          });
                        }
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginTop: '1rem',
                        padding: '0.5rem 0.75rem',
                      }}
                      className={clsx(
                        'payment-card' +
                          ` ${
                            plan.name === selectedPlan.name
                              ? 'selected-payment'
                              : ''
                          }`,
                        'payment-card-item'
                      )}
                      onKeyDown={(e) =>
                        handleOnKeyDown(e, () => setSelectedPlan(plan))
                      }
                      tabIndex={0}
                      role="button"
                    >
                      {isMobile ? (
                        <Fragment>
                          <Radio value={plan.name} />{' '}
                          <div className="payment-card-item-price-mobile">
                            <div>
                              <div
                                style={{ fontSize: '16px', fontWeight: '500' }}
                              >
                                {plan.price}
                              </div>
                              <div style={{ fontSize: '12px' }}>
                                {plan.paymentCycleText}
                              </div>
                            </div>
                            {plan.tag && (
                              <div style={{ marginRight: '1rem' }}>
                                <div
                                  style={{
                                    borderRadius: '4px',
                                    fontSize: '12px',
                                    background: plan.tag?.color,
                                    padding: '0.25rem 0.5rem',
                                  }}
                                >
                                  {'🔥 '}
                                  {plan.tag?.text.split('Save')[0]}
                                  <span style={{ fontWeight: '700' }}>
                                    {' Save'}
                                    {plan.tag?.text.split('Save')[1]}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div style={{ display: 'flex' }}>
                            <Radio value={plan.name} />{' '}
                            <div>
                              <div
                                style={{ fontSize: '16px', fontWeight: '500' }}
                              >
                                {plan.price}
                              </div>
                              <div style={{ fontSize: '12px' }}>
                                {plan.paymentCycleText}
                              </div>
                            </div>
                          </div>

                          {plan.tag && (
                            <div style={{ marginRight: '1rem' }}>
                              <div
                                style={{
                                  borderRadius: '4px',
                                  fontSize: '12px',
                                  background: plan.tag?.color,
                                  padding: '0.25rem 0.5rem',
                                }}
                              >
                                {'🔥 '}
                                {plan.tag?.text.split('Save')[0]}
                                <span style={{ fontWeight: '700' }}>
                                  {' Save'}
                                  {plan.tag?.text.split('Save')[1]}
                                </span>
                              </div>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>
                  ))}
                </Radio.Group>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                  }}
                >
                  <Button
                    type={'primary'}
                    style={{
                      marginTop: '1.5rem',
                    }}
                    className="upgrade-to-premium"
                    size="large"
                    loading={paymentLoading}
                    onClick={() => {
                      if (isOnboarding) {
                        analyticsEvent(
                          'Onboarding  - Pricing plans - Buy premium',
                          'Onboarding'
                        );
                      } else {
                        analyticsEvent(
                          'Go to stripe payments page for upgrade',
                          selectedPlan?.name
                        );
                      }

                      setPaymentPageVisited(true);

                      handleSubscribe({
                        priceId: selectedPlan?.stripePriceId,
                        referralId: institute?.referralId,
                      });
                    }}
                  >
                    {pricingData?.buttonText}
                  </Button>
                  {appConfig?.features?.redeemVoucher?.enabled && (
                    <Fragment>
                      {openRedeemVoucherModal ? (
                        <div
                          style={{
                            display: 'flex',
                            gap: '1rem',
                            justifyContent: 'end',
                          }}
                          className="redeem-voucher-container"
                        >
                          <Input
                            placeholder="Enter Voucher Code"
                            onChange={(e) => setVoucherCode(e.target.value)}
                            size="middle"
                          ></Input>
                          <div
                            className="redeem-voucher-button-container"
                            style={{ display: 'flex', gap: '1rem' }}
                          >
                            <Button
                              loading={redeemLoading}
                              onClick={() => {
                                redeemVoucherCode();
                                if (isOnboarding) {
                                  analyticsEvent(
                                    'Onboarding  - Redeem coupon - Buy premium',
                                    'Onboarding'
                                  );
                                }
                              }}
                            >
                              Redeem
                            </Button>
                            <Button
                              onClick={() => setOpenRedeemVoucherModal(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <Button
                          onClick={() => setOpenRedeemVoucherModal(true)}
                          type="link"
                        >
                          Redeem Voucher
                        </Button>
                      )}
                    </Fragment>
                  )}
                </div>
                <Divider className="divider" />
                <div className="subtext">
                  {pricingData?.plansSectionSubtext}
                </div>
              </Col>
            </Row>
          </div>
        )
      )}
    </div>
  );
};

export default PaymentsPlanV2;
