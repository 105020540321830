import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import UserProvider from './auth';
import RedirectPage from './pages/RedirectPage/RedirectPage';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import ErrorBoundary from './ErrorBoundary';

window.addEventListener('vite:preloadError', (_event) => {
  window.location.reload(); // for example, refresh the page
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.VITE_APP_ENVIRONMENT === 'PRODUCTION') {
  document
    .querySelector('#gsc')
    ?.setAttribute('content', 'odSKtUMRASQiyFyKotptp4Mnx_Y0sLgvazb-j2tpS9E');
  document
    .querySelector('#gsc')
    ?.setAttribute('name', 'google-site-verification');
}

root.render(
  <ErrorBoundary>
    <Router
      future={{
        v7_relativeSplatPath: true,
      }}
    >
      <UserProvider>
        <App />
      </UserProvider>
    </Router>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
