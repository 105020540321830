import {
  CopyOutlined,
  DislikeFilled,
  DislikeOutlined,
  EditOutlined,
  LikeFilled,
  LikeOutlined,
  LockOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, message, Modal, Skeleton } from 'antd';

import stars from 'images/stars.svg';
import styles from './HeadlineGenerator.module.scss';
import { useCallback, useContext, useRef, useState } from 'react';
import { AppContext, InitialState } from '../../../auth';
import TextArea from 'antd/lib/input/TextArea';
import PDFGenerator from '../../common/PdfGenerator';
import { aiServiceBasePath } from '../../../utils/apiPaths';
import axios from 'axios';
import { CustomLoader } from '../../common/CustomLoader';
import { useTranslation } from 'react-i18next';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';

function convertHtmlToText(html: any) {
  // Remove opening and closing <div> tags
  const withoutDivTags = html.replace(/<\/?div[^>]*>/g, '');

  // Replace <br> tags with newline characters (\n)
  const plainText = withoutDivTags.replace(/<br\s*[/]?>/gi, '\n');

  return plainText;
}

const AIResults = ({
  sectionName,
  resultLoading,
  feedbackRecorded,
  result,
  onGenerate,
  setResult,
  setUpgrade,
  aiResponseId,
  setFeedbackRecorded,
  resultStyle,
}: any) => {
  const { userInfo } = useContext(AppContext) as InitialState;
  const [feedback, setFeedback] = useState<any>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [openAdditionalFeedbackModal, setOpenAdditionalFeedbackModal] =
    useState(false);
  const { t } = useTranslation();
  const sectionNameToPromptType = new Map([
    ['About', 'about'],
    [t('CoverLetter'), 'cover-letter'],
    [t('Headline'), 'headline'],
    ['Post', 'linkedin-post'],
    ['Personal Brand Statement', 'brand-statement'],
    ['Email Writer', 'email-writer'],
  ]);

  const handleEditSave = () => {
    setSaveLoading(true);
    const resultWithoutHTML = convertHtmlToText(resultRef?.current?.innerHTML);

    axios
      .put(aiServiceBasePath + `/ai/${aiResponseId}`, {
        response: {
          content: resultWithoutHTML,
        },
      })
      .then(() => message.success('Updates Saved'))
      .catch((error) => console.log(error))
      .finally(() => {
        setEditResult(false);
        setResult(resultWithoutHTML);
        setSaveLoading(false);
      });
  };

  const recordFeedback = useCallback(
    (fbk?: any) => {
      axios
        .post(aiServiceBasePath + `/feedback/${aiResponseId}`, fbk || feedback)
        .then(() => message.success('Feedback Submitted'))
        .catch((error) => console.log(error))
        .finally(() => {
          setFeedbackRecorded(true);
        });
    },
    [aiResponseId, feedback, setFeedbackRecorded]
  );

  const copyToClipboard = async () => {
    try {
      analyticsEvent('Copy AI response', undefined, {
        aiResponseId,
        responseType: sectionName,
      });
      await navigator.clipboard.writeText(result);
      message.success(t('copyToClipboard'));
    } catch (err) {
      message.error('Failed to copy text!');
    }
  };

  const resultRef = useRef<HTMLInputElement>(null);
  const [editResult, setEditResult] = useState(false);
  return (
    <>
      {userInfo.userType !== 'premium' && (
        <div className={styles.upgradeLockScreen}>
          <div>
            <LockOutlined style={{ fontSize: '70px', color: '#4280DC' }} />
          </div>
          <div style={{ width: '40%' }} className={'sub-heading'}>
            {t('UnlocktheAIgenerated')} {sectionName}
          </div>
          <Button
            onClick={() => setUpgrade(true)}
            className={'upgradePremiumButton'}
          >
            {t('UpgradetoPremium')}
          </Button>
        </div>
      )}
      <div
        style={userInfo.userType !== 'premium' ? { filter: 'blur(2px)' } : {}}
      >
        <div style={{ minHeight: '5rem' }} className={'dottedBg'}>
          <div
            style={{
              padding: '1rem',
              marginLeft: '0.5rem',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <img src={stars} alt="stars" /> {t('Result')}
            </div>
            {result && (
              <div>
                <Button onClick={copyToClipboard} className="primary-btn">
                  {t('Copy')} <CopyOutlined />
                </Button>
                {editResult ? (
                  <Button
                    style={{ margin: '0 1rem' }}
                    onClick={handleEditSave}
                    loading={saveLoading}
                  >
                    {t('Save')}
                  </Button>
                ) : (
                  <Button
                    style={{ margin: '0 1rem' }}
                    onClick={() => {
                      setEditResult(true);
                      resultRef?.current?.focus();
                    }}
                  >
                    {t('Edit')} <EditOutlined />
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            boxShadow: editResult ? '0 0 2px 2px #8fc4f9' : '',
            ...resultStyle,
          }}
          className={styles.resultBox}
        >
          {resultLoading || userInfo.userType !== 'premium' ? (
            <div>
              {userInfo.userType === 'premium' && (
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    alignContent: 'center',
                  }}
                >
                  <CustomLoader /> {t('Processing')}...
                </div>
              )}
              <div style={{ marginTop: '2rem' }}>
                <Skeleton active paragraph={{ rows: 11 }} />
              </div>
            </div>
          ) : result ? (
            <div
              contentEditable={editResult}
              ref={resultRef}
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: result }}
            ></div>
          ) : (
            <div
              style={{
                color: '#96A0B5',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              {t('YourAIgeneratedcontentwillshowhere')}
            </div>
          )}
        </div>
        {result && (
          <div className={styles.actionButtonsDiv}>
            {!feedbackRecorded && (
              <div className={styles.feedbackContainer}>
                <div>{t('hopeYouFindUseful')}</div>
                <div>
                  <Button
                    onClick={() => {
                      setFeedback((fbk: any) => ({
                        ...fbk,
                        reaction: 'like',
                      }));
                      setOpenAdditionalFeedbackModal(true);
                      recordFeedback({ reaction: 'like' });
                    }}
                  >
                    <LikeOutlined />
                  </Button>{' '}
                  <Button
                    onClick={() => {
                      setFeedback((fbk: any) => ({
                        ...fbk,
                        reaction: 'dislike',
                      }));
                      setOpenAdditionalFeedbackModal(true);
                      recordFeedback({ reaction: 'dislike' });
                    }}
                  >
                    <DislikeOutlined />
                  </Button>
                </div>
              </div>
            )}
            <Button
              size={'large'}
              className="tertiary-btn"
              onClick={() => {
                setEditResult(false);
                onGenerate();
              }}
              loading={resultLoading}
            >
              {t('regenerate')} <ReloadOutlined />
            </Button>{' '}
            {!resultLoading ? (
              <PDFGenerator
                text={result}
                promptType={sectionNameToPromptType.get(sectionName)}
                saveLoading={saveLoading}
                editResult={editResult}
              />
            ) : null}
          </div>
        )}
      </div>
      <Modal
        onOk={() => {
          recordFeedback();
          setOpenAdditionalFeedbackModal(false);
        }}
        onCancel={() => setOpenAdditionalFeedbackModal(false)}
        okText={'Submit Feedback'}
        open={openAdditionalFeedbackModal}
      >
        <div
          className={'modalHeading'}
          style={{ borderBottom: '1px solid rgba(225, 237, 255, 1)' }}
        >
          {' '}
          {feedback?.reaction !== 'dislike' ? (
            <LikeFilled />
          ) : (
            <DislikeFilled />
          )}{' '}
          {t('ProvideAdditionalFeedback')}
        </div>
        <TextArea
          style={{ margin: '1.5rem', width: '90%' }}
          placeholder={t('Enteranyfeedback')}
          onChange={(e) =>
            setFeedback((fbk: any) => ({ ...fbk, text: e.target.value }))
          }
        />
        {feedback?.reaction === 'dislike' && (
          <div style={{ margin: '1.5rem', marginTop: '0' }}>
            <div>
              <Checkbox
                checked={feedback?.dislikeReasons?.notImpressive}
                onChange={(e) =>
                  setFeedback((fbk: any) => ({
                    ...fbk,
                    dislikeReasons: {
                      ...fbk.dislikeReasons,
                      notImpressive: e.target.checked,
                    },
                  }))
                }
              />{' '}
              {t('Notimpressive')}
            </div>
            <div>
              <Checkbox
                checked={feedback?.dislikeReasons?.notTrue}
                onChange={(e) =>
                  setFeedback((fbk: any) => ({
                    ...fbk,
                    dislikeReasons: {
                      ...fbk.dislikeReasons,
                      notTrue: e.target.checked,
                    },
                  }))
                }
              />{' '}
              {t('thisIsntTrue')}
            </div>
            <div>
              <Checkbox
                checked={feedback?.dislikeReasons?.notPersonalized}
                onChange={(e) =>
                  setFeedback((fbk: any) => ({
                    ...fbk,
                    dislikeReasons: {
                      ...fbk.dislikeReasons,
                      notPersonalized: e.target.checked,
                    },
                  }))
                }
              />{' '}
              {t('Notpersonalized')}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AIResults;
