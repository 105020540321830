import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { linkedinProfileApiPath } from '../../utils/apiPaths';
import { auth } from '../../utils/firebase/firebaseIndex';

// Create axios instance for Chrome Extension
export const extensionClient = axios.create({
  baseURL: process.env.VITE_APP_BACKEND_BASE_URL + '/chromeExtension',
});

// Interceptor to attach Authorization token to requests
extensionClient.interceptors.request.use(
  async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

// Method to get LinkedIn profile
const getProfile = (userid: string): Promise<AxiosResponse> => {
  return extensionClient.get(`${linkedinProfileApiPath}?userid=${userid}`);
};

// Exporting the service as default
const ChromeExtensionService = {
  getProfile,
};

export default ChromeExtensionService;
