import React from 'react';
import jsPDF from 'jspdf';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handleOnKeyDown } from '../../../utils/eventListeners';

const PDFGenerator = ({
  text,
  historyObject = null,
  promptType = null,
  children,
  saveLoading,
  editResult,
}: any) => {
  const { t } = useTranslation();

  const decodeHtmlEntities = (text) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = text;
    return txt.value;
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const textWidth = 180; // Width of the text container
    const marginLeft = 15; // Left margin of the text container
    const marginTop = 20; // Top margin of the text container

    doc.setFont('Helvetica', 'normal'); // Set font and style
    doc.setFontSize(12); // Set font size
    doc.setTextColor(0, 0, 0); // Set text color (RGB)
    const plainText = decodeHtmlEntities(text);
    const textLines = doc.splitTextToSize(plainText, textWidth);
    let title = 'output';
    const inputPromptType = promptType || historyObject?.promptType;
    switch (inputPromptType) {
      case 'cover-letter':
        title = [
          'Cover Letter',
          historyObject?.inputs?.companyName,
          historyObject?.inputs?.targetJobTitle,
        ]
          .filter(Boolean)
          .join('_')
          .replace(/\s/g, '')
          .replace(/[^A-Za-z0-9-_]/g, '');
        break;
      case 'about':
        title = 'LinkedIn_About';
        break;
      case 'headline':
        title = 'LinkedIn_Headline';
        break;
      case 'linkedin-post':
        title = 'LinkedIn_Post';
        break;
    }
    doc.text(textLines, marginLeft, marginTop);
    doc.save(title + '.pdf');
  };

  return (
    <div>
      {children ? (
        <div
          onClick={generatePDF}
          onKeyDown={(e) => handleOnKeyDown(e, generatePDF)}
          tabIndex={0}
          role="button"
        >
          {children}
        </div>
      ) : (
        <Button
          disabled={saveLoading || editResult}
          size={'large'}
          className="primary-btn"
          onClick={generatePDF}
        >
          {t('DownloadPDF')} <DownloadOutlined />
        </Button>
      )}
    </div>
  );
};

export default PDFGenerator;
