import { Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './Tooltip.scss';

const EllipsisTooltip = ({
  children,
  title,
}: {
  children: React.ReactElement;
  title: string;
}) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        setShowTooltip(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkEllipsis();
    window.addEventListener('resize', checkEllipsis);
    return () => window.removeEventListener('resize', checkEllipsis);
  }, [title]);

  const styledChildren = React.cloneElement(children, {
    ref: textRef,
    style: {
      ...children?.props?.style,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });

  return showTooltip ? (
    <Tooltip title={title}>{styledChildren}</Tooltip>
  ) : (
    styledChildren
  );
};

export default EllipsisTooltip;
