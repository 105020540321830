import { Component, ErrorInfo } from 'react';
import Fallback from './Fallback';
import axios from 'axios';
import { webappErrorReportApiPath } from './utils/apiPaths';
import json from '../package.json';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

function FallbackComponent() {
  return <Fallback />;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: ErrorBoundary) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorDetails = {
      error: error.toString(),
      errorInfo: `updated version with layout fix: ${json.version}, ${errorInfo.componentStack}`,
      url: window.location.href,
    };

    console.error(error, errorInfo);

    const errors = [
      'ChunkLoadError',
      'Unable to preload CSS',
      'Failed to fetch dynamically imported module',
      'Minified React error',
      'Invariant failed',
    ];

    if (errors.some((item) => error.message.includes(item))) {
      window.location.reload();
      return;
    } else {
      this.setState({ hasError: true });

      axios.post(webappErrorReportApiPath, errorDetails).catch((err) => {
        console.log(err);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <FallbackComponent />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
