import {
  DownloadOutlined,
  HistoryOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Input, Radio, Row, message } from 'antd';
import axios from 'axios';
import clsx from 'clsx';

import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIndexedDB } from 'react-indexed-db-hook';
import { AppContext, InitialState } from '../../../../auth';
import fileAdd from 'images/fileAdd.svg';
import nodocument from 'images/noDocuments.png';

import ChromeExtensionService from '../../../../services/api/ChromeExtensionService';
import { aiServiceBasePath } from '../../../../utils/apiPaths';
import { auth } from '../../../../utils/firebase/firebaseIndex';

import Loader from '../../../common/Loader';
import PdfParser from '../../../common/PdfParser';
import styles from './HeadlineGenerator.module.scss';

import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { useTranslation } from 'react-i18next';
import AIResults from '../AIResults';
import AIToolHistoryCard from '../CoverLetter/HistoryCard';
import LanguageDropdown from '../LanguageDropdown';

import { RightOutlined } from '@ant-design/icons';
import useAIToolQueryParamSetting from '@careerflow/common-components/dist/customHooks/useAIToolQueryParamSetting';
import AiToolsService from '@careerflow/common-components/dist/services/AIToolsService';
import ServiceFactory, {
  SERVICE_CLIENTS,
} from '@careerflow/common-components/dist/services/ServiceFactory';
import { Collapse } from 'antd';
import AiDocuments from '../AiDocuments/AiDocuments';
import FollowupInput from '../FollowupInput';

import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import aiService from '../../../../services/api/AIService';

const PersonalBrandStatement: React.FC<any> = ({ setUpgrade }) => {
  const [resultLoading, setResultLoading] = useState(false);

  const { getAll } = useIndexedDB('resume');
  const [keywords_to_include, setKeyWordsToInclude] = useState('');
  const [historyLoading, setHistoryLoading] = useState(false);
  const [feedbackRecorded, setFeedbackRecorded] = useState<boolean>(false);
  const [aiResponseId, setAiResponseId] = useState();
  const [headlineHistory, setHeadlineHistory] = useState<Array<any>>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [result, setResult] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [profileSelection, setProfileSelection] = useState('resume');
  const [linkedinProfile, setLinkedinProfile] = useState<any>();

  const [additionalContext, setAdditionalContext] = useState('');
  const [resultTone, setResultTone] = useState('');

  const [resultLength, setResultLength] = useState<any>('medium');

  const [user] = useAuthState(auth);
  const [resumeText, setResumeText] = useState('');

  const [resumeForResult, setResumeForResult] = useState<any>();

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [languageChangeMode, setLanguageChangeMode] = useState(false);
  const [followupPrompt, setFollowupPrompt] = useState('');

  const { userInfo, institute, appConfig } = React.useContext(
    AppContext
  ) as InitialState;

  const toolname = 'Personal Brand Statement';

  const { t } = useTranslation();

  useAIToolQueryParamSetting({
    setSelectedLanguage,
    setJobTitle,
    setKeyWordsToInclude,
  });

  const aiCommonService = ServiceFactory.getInstance().getService(
    SERVICE_CLIENTS.AI_TOOLS_SERVICE_CLIENT
  ) as AiToolsService;

  useEffect(() => {
    if (user)
      ChromeExtensionService.getProfile(user.uid).then((response) => {
        if (!response.data) return;
        const filteredData: any = {
          about: response.data.about,
          education: response.data.education,
          certifications: response.data.extras.licenses_certs,
          name: response.data.extras.name,
          headline: response.data.heading,
          jobs: response.data.jobs,
          location: response.data.location,
          skills: response.data.skills,
        };

        setLinkedinProfile(filteredData);
      });
  }, [user]);

  useEffect(() => {
    getAll().then((resumes) => {
      for (const resume of resumes) {
        if (resume.default) {
          setResumeForResult(resume);
          break;
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showHistory) setHistoryLoading(true);
    axios
      .get(aiServiceBasePath + '/history/brand-statement')
      .then(({ data }) => {
        setHeadlineHistory(data);
      })
      .finally(() => setHistoryLoading(false));
  }, [showHistory]);

  const viewDetailHandler = (historyObject: any) => {
    if (historyObject.inputs) {
      setShowHistory(false);
      setFeedbackRecorded(historyObject.feedback);
      setAiResponseId(historyObject.id);
      setResult(historyObject.response?.content || historyObject.response);
    } else {
      message.error(
        'There is a problem while loading history for brand statement.'
      );
    }
  };

  const onGenerate = () => {
    setResultLoading(true);
    analyticsEvent('Generate Personal Brand Statement post');
    aiService
      .personalBrandStatement({
        targetJobTitle: jobTitle,
        name: userInfo.fname,
        previousResult: result,
        followupPrompt,
        keywords_to_include: keywords_to_include.split(/\s+|,\s+/g),
        resume: profileSelection === 'resume' ? resumeText : '',
        resumeId: resumeForResult?.id,
        linkedin_profile: profileSelection !== 'resume' ? linkedinProfile : '',
        source: 'Webapp',
        length: resultLength,
        tone: resultTone,
        additionalContext,
        instituteId: institute?.id,
        language: selectedLanguage,
      })
      .then((res) => {
        setFeedbackRecorded(false);
        setAiResponseId(res.data?.id);
        setResult(JSON.parse(res.data?.content || {})?.statement);
      })
      .catch((e) => {
        console.error(e);
        message.error(
          'There was a problem while generating the Personal Brand.'
        );
      })
      .finally(() => {
        setResultLoading(false);
      });
  };

  if (showHistory) {
    if (historyLoading) {
      return <Loader />;
    } else
      return (
        <div
          style={{
            width: '75%',
            flexDirection: 'column',
            display: 'flex',
            margin: '2rem auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              marginBottom: '2rem',
            }}
          >
            <LeftOutlined
              style={{ color: '#40a9ff', fontSize: '18px' }}
              onClick={() => setShowHistory(false)}
            />
            <div className={'sub-heading'}>{t(`${toolname} History`)}</div>
          </div>

          {!headlineHistory?.length && (
            <div className={styles.noContact}>
              <div>
                <img src={nodocument} height={110} alt="no contact" />
              </div>
              <div className={styles.heading}>
                {t('No Personal Brand Statements Generated')}
              </div>
              <div style={{ width: '60%' }}>
                {t('You have not generated any personal brand statements yet.')}
              </div>

              <Button
                className={'createBtn'}
                onClick={() => setShowHistory((prev) => !prev)}
              >
                {t('getStarted')}
              </Button>
            </div>
          )}

          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
          >
            {headlineHistory?.map((historyItem: any) => (
              <AIToolHistoryCard
                historyObject={historyItem}
                onViewDetails={viewDetailHandler}
              />
            ))}
          </div>
        </div>
      );
  } else {
    return (
      <>
        <AiDocuments
          setResumeForResult={setResumeForResult}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
        <Row className={styles.root}>
          <Col
            style={{ height: '85vh' }}
            span={22}
            md={11}
            className={styles.contentBox}
          >
            {!result ? (
              <>
                <div className={styles.inputSectionHeader}>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="sub-heading">
                        {t('Personal Brand Statement')}
                      </span>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => setShowHistory(true)}
                      style={{ color: 'black' }}
                      className="tertiary-btn"
                    >
                      {t('ViewHistory')}{' '}
                      <HistoryOutlined style={{ color: '#1570EF' }} />
                    </Button>
                  </div>
                </div>
                <div className={styles.inputsForm}>
                  <>
                    <div>{t('TargetJobTitle')}*</div>
                    <Input
                      type="text"
                      placeholder={t('EnterJobTitle')}
                      className={styles.input}
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                    <div>{t('KeywordstoInclude')}</div>
                    <Input
                      type="text"
                      placeholder={t('Pressenteraftereachword')}
                      className={styles.input}
                      value={keywords_to_include}
                      onChange={(e) => setKeyWordsToInclude(e.target.value)}
                    />
                    <Divider style={{ margin: '12px 0' }} />
                    <div className="large-text" style={{ margin: '0.5rem 0' }}>
                      {t('YourProfile')}
                    </div>
                    <div>
                      <Radio.Group
                        className={styles.input}
                        style={{ display: 'flex', gap: '0.75rem' }}
                        onChange={(e: any) => {
                          setProfileSelection(e.target?.value);
                        }}
                        value={profileSelection}
                      >
                        <div className={styles.radioInput}>
                          <Radio value={'resume'}>{t('ResumeUpload')}</Radio>
                        </div>

                        <div className={styles.radioInput}>
                          <Radio value={'linkedin'}>
                            {t('UseLinkedinProfile')}
                          </Radio>
                        </div>
                      </Radio.Group>
                      {profileSelection === 'resume' ? (
                        <div>
                          {!resumeForResult ? (
                            <div
                              style={{
                                border: '1px solid #e1edff',
                                borderRadius: '8px',
                                marginTop: '1rem',
                              }}
                            >
                              <div
                                style={{
                                  borderBottom:
                                    '1px solid rgba(225, 237, 255, 1)',
                                  borderRadius: '8px 8px 0 0',
                                  padding: '0.75rem 1rem',
                                  background: 'rgba(248, 251, 254, 1)',
                                }}
                                className={'bold-sub-text'}
                              >
                                {t('UploadYourResume')}
                              </div>

                              <div className={styles.fullWidth}>
                                <div
                                  style={{
                                    flexDirection: 'column',
                                    padding: '1.5rem 0',
                                  }}
                                  className={clsx('flex-center')}
                                >
                                  <img src={fileAdd} alt="file add" />
                                  <div className="bold-text">
                                    {t('AddyourResume')}
                                  </div>
                                  <div className="small-sub-text">
                                    {t('fileNameCannotFormat')}
                                  </div>
                                  <Button
                                    style={{ marginTop: '1rem' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setIsModalVisible(true);
                                    }}
                                    type="primary"
                                    className={'createBtn'}
                                  >
                                    {t('ChooseorUpload')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <PdfParser
                              onTextExtracted={(e: any) => {
                                setResumeText(e);
                              }}
                              selectedResume={resumeForResult}
                              isModalVisible={isModalVisible}
                              setIsModalVisible={setIsModalVisible}
                              setSelectedResume={setResumeForResult}
                            />
                          )}
                        </div>
                      ) : (
                        !linkedinProfile && (
                          <div>
                            <div
                              style={{
                                border: '1px solid #e1edff',
                                borderRadius: '8px',
                                marginTop: '1rem',
                              }}
                            >
                              <div
                                style={{
                                  borderBottom:
                                    '1px solid rgba(225, 237, 255, 1)',
                                  borderRadius: '8px 8px 0 0',
                                  padding: '0.75rem 1rem',
                                  background: 'rgba(248, 251, 254, 1)',
                                }}
                                className={'bold-sub-text'}
                              >
                                {t('LinkedinProfile')}
                              </div>

                              <div className={styles.fullWidth}>
                                <div
                                  style={{
                                    flexDirection: 'column',
                                    padding: '1.5rem 0',
                                  }}
                                  className={clsx('flex-center')}
                                >
                                  <div className="bold-text">
                                    {t('LinkedinProfilenotfound')}{' '}
                                  </div>
                                  <div
                                    style={{
                                      color: '#96a0b5',
                                      fontSize: '12px',
                                      fontWeight: '300',
                                    }}
                                  >
                                    {t('startUsingChromeExtension')}
                                  </div>
                                  <Button
                                    style={{ marginTop: '1rem' }}
                                    type="primary"
                                    onClick={() =>
                                      window.open(
                                        'https://chrome.google.com/webstore/detail/careerflows-linkedin-opti/iadokddofjgcgjpjlfhngclhpmaelnli'
                                      )
                                    }
                                  >
                                    {t('Download')} <DownloadOutlined />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <Divider style={{ margin: '24px 0 12px 0' }} />
                    <div
                      className="advancedOptionsCollapse"
                      style={{ margin: '1rem 0' }}
                    >
                      <Collapse
                        ghost
                        expandIconPosition="right"
                        expandIcon={({ isActive }) => (
                          <RightOutlined rotate={isActive ? 270 : 90} />
                        )}
                      >
                        <CollapsePanel
                          header={
                            <div className="large-text">
                              {t('AdvancedSettings')}
                            </div>
                          }
                          key="1"
                        >
                          <div
                            style={{
                              border: '1px solid #E5EBF0',
                              background: '#FBFCFC',
                              padding: '0 1rem',
                              margin: '0.5rem 0',
                            }}
                          >
                            <div style={{ margin: '0.5rem 0' }}>
                              {t('AdditionalContext')}
                            </div>
                            <Input.TextArea
                              style={{ height: 100 }}
                              value={additionalContext}
                              placeholder={t(
                                'Enter any additional instructions or information'
                              )}
                              className={styles.input}
                              onChange={(e) =>
                                setAdditionalContext(e.target.value)
                              }
                            />
                            <div style={{ margin: '0.5rem 0' }}>
                              {t('Tone')}
                            </div>
                            <div>
                              <Radio.Group
                                className={styles.input}
                                style={{
                                  display: 'flex',
                                  gap: '0.75rem',
                                  flexWrap: 'wrap',
                                }}
                                onChange={(e: any) =>
                                  setResultTone(e.target?.value)
                                }
                                value={resultTone}
                              >
                                <div>
                                  <Radio value={'professional'}>
                                    {t('Professional')}
                                  </Radio>
                                </div>
                                <div>
                                  <Radio value={'casual'}>{t('Casual')}</Radio>
                                </div>
                                <div>
                                  <Radio value={'enthusiastic'}>
                                    {t('Enthusiastic')}
                                  </Radio>
                                </div>
                                <div>
                                  <Radio value={'informational'}>
                                    {t('Informational')}
                                  </Radio>
                                </div>
                              </Radio.Group>
                            </div>
                            <div>{t('Length')}</div>
                            <div>
                              <Radio.Group
                                className={styles.input}
                                style={{ display: 'flex', gap: '0.75rem' }}
                                onChange={(e: any) =>
                                  setResultLength(e.target?.value)
                                }
                                value={resultLength}
                              >
                                <div>
                                  <Radio value={'short'}>{t('Short')}</Radio>
                                </div>
                                <div>
                                  <Radio value={'medium'}>{t('Medium')}</Radio>
                                </div>
                                <div>
                                  <Radio value={'long'}>{t('Long')}</Radio>
                                </div>
                              </Radio.Group>
                            </div>
                            <div style={{ marginTop: '1.5rem' }}>
                              {t('Language')}
                            </div>
                            <LanguageDropdown
                              setSelectedLanguage={setSelectedLanguage}
                              selectedLanguage={selectedLanguage}
                              languageChangeMode={languageChangeMode}
                              setLanguageChangeMode={setLanguageChangeMode}
                              style={{ margin: '0.75rem' }}
                            />
                          </div>
                        </CollapsePanel>
                      </Collapse>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <FollowupInput
                followupPrompt={followupPrompt}
                setFollowupPrompt={setFollowupPrompt}
                toolname={toolname}
              />
            )}
            <div
              style={{
                padding: '1rem  1.5rem 1rem 1.5rem',
                display: 'flex',
                gap: '1rem',
              }}
              className={styles.generateDiv}
            >
              {result && (
                <Button
                  style={{ width: '100%', marginBottom: '1rem' }}
                  className={'tertiary-btn'}
                  size="large"
                  onClick={() => setResult('')}
                  disabled={resultLoading}
                >
                  {t('iAmOkayBack')}
                </Button>
              )}
              <Button
                style={{ width: '100%' }}
                type="primary"
                size="large"
                loading={resultLoading}
                onClick={onGenerate}
                disabled={
                  userInfo.userType !== 'premium' ||
                  (!linkedinProfile && !resumeForResult)
                }
                className={'createBtn'}
              >
                {t('Generate')}
              </Button>
            </div>
          </Col>
          <Col
            style={{ height: 'fit-content' }}
            span={22}
            md={11}
            className={styles.contentBox}
          >
            <AIResults
              sectionName={t('personal-brand')}
              resultLoading={resultLoading}
              feedbackRecorded={feedbackRecorded}
              result={result}
              setResult={setResult}
              onGenerate={onGenerate}
              setUpgrade={setUpgrade}
              aiResponseId={aiResponseId}
              setFeedbackRecorded={setFeedbackRecorded}
            />
          </Col>
        </Row>
      </>
    );
  }
};

export default React.memo(PersonalBrandStatement);
