import {
  DownOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, MenuProps, Space, Tag } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import aboutGeneratorImg from 'images/AboutGenerator.svg';
import headlineGeneratorImg from 'images/HeadlineGenerator1.svg';
import linkedINPostGeneratorImg from 'images/LinkedINPostGenerator.svg';
import coverLetterImg from 'images/coverletterIcon.svg';
import elevatorPitchIcon from 'images/elevatorPitchToolIcon.svg';
import emailGenImg from 'images/emailGenerator.svg';
import mockInterviewImg from 'images/mockinterviewMenuitem.svg';
import logo from 'images/newLogoLight.png';
import personalBrandImg from 'images/personalBrandStatementGenerator.svg';
import premiumStar from 'images/premiumStar.svg';
import starsIcon from 'images/stars.svg';
import { AppContext, InitialState } from '../../../auth';
import ThemeColors from '../../../theme/themeColors';
import ensureHttps from '../../../utils/ensureHttps';
import ProfileInfo from '../../../pages/ProfileInfo/ProfileInfo';
import style from './Navbar.module.scss';

export enum INVITE_STATES {
  accepted = 'ACCEPTED',
  invited = 'INVITED',
  canceled = 'CANCELED',
  deactivated = 'DEACTIVATED',
}

const Navbar = ({
  isMenuUnfolded,
  setIsMenuUnfolded,
  setUpgrade,
}: {
  isMenuUnfolded: boolean;
  setIsMenuUnfolded: (callback: (state: boolean) => boolean) => void;
  setUpgrade: any;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isMobile,
    userInfo,
    appConfig,
    institute,
    selectedInstitute,
    isInstituteCoach,
  } = useContext(AppContext) as InitialState;

  const items: MenuProps['items'] = [
    {
      label: (
        <div className={style.menuItem}>
          <div className={style.menuIconAi}>
            <img
              height={40}
              style={{ borderRadius: '120px' }}
              src={coverLetterImg}
              alt={'cover letter icon'}
            />
          </div>
          <div>{t('CoverLetter')}</div>
        </div>
      ),
      key: '1',
      onClick: () => {
        navigate('/cover-letter');
      },
    },
  ];

  if (
    !institute?.disabledFeatures?.includes('ai-personal-brand-statement') &&
    appConfig?.features?.aiPersonalBrandAndEmail?.enabled
  ) {
    items.push(
      ...[
        {
          label: (
            <div className={style.menuItem}>
              <div className={style.menuIconAi}>
                <img
                  height={40}
                  style={{ borderRadius: '120px' }}
                  src={personalBrandImg}
                  alt={'Personal Brand Statement'}
                />
              </div>
              <div>{t('Personal Brand Statement')}</div>
              <Tag color="green" style={{ marginLeft: '1rem' }}>
                New
              </Tag>
            </div>
          ),
          key: '10',
          onClick: () => {
            navigate('/brand-statement');
          },
        },
        {
          label: (
            <div className={style.menuItem}>
              <div className={style.menuIconAi}>
                <img
                  height={40}
                  style={{ borderRadius: '120px' }}
                  src={emailGenImg}
                  alt={'email writer'}
                />
              </div>
              <div>{t('Email Writer')}</div>
              <Tag color="green" style={{ marginLeft: '1rem' }}>
                New
              </Tag>
            </div>
          ),
          key: '11',
          onClick: () => {
            navigate('/job-outreach-email-writer');
          },
        },
      ]
    );
  }

  if (appConfig?.features?.elevatorPitch?.enabled === true)
    items.push({
      label: (
        <div className={style.menuItem}>
          <div className={style.menuIconAi}>
            <img
              height={40}
              style={{ borderRadius: '120px' }}
              src={elevatorPitchIcon}
              alt={'elevator pitch icon'}
            />{' '}
          </div>
          <div>{t('ElevatorPitch')}</div>
        </div>
      ),
      key: '5',
      onClick: () => {
        navigate('/elevator-pitch');
      },
    });

  if (
    appConfig?.features?.mockInterview?.enabled === true &&
    (!Object.keys(institute || {}).length ||
      institute?.mockinterviewEnabled === true)
  ) {
    items.push({
      label: (
        <div className={style.menuItem}>
          <div className={style.menuIconAi}>
            <img
              height={40}
              style={{ borderRadius: '120px' }}
              src={mockInterviewImg}
              alt={'mock interview icon'}
            />
          </div>
          <div>{t('MockInterview')}</div>
        </div>
      ),
      key: '0',
      onClick: () => {
        if (institute?.customMockInterviewUrl) {
          return window.open(ensureHttps(institute?.customMockInterviewUrl));
        }
        navigate('/mock-interview');
      },
    });
  }

  if (!institute?.disabledFeatures?.includes('linkedin')) {
    items.push(
      ...[
        {
          label: (
            <div className={style.menuItem}>
              <div className={style.menuIconAi}>
                <img
                  height={40}
                  style={{ borderRadius: '120px' }}
                  src={headlineGeneratorImg}
                  alt={'linkedin headline generator icon'}
                />
              </div>
              <div>{t('LinkedinHeadlineGenerator')}</div>
            </div>
          ),
          key: '3',
          onClick: () => {
            navigate('/linkedin-headline');
          },
        },
        {
          label: (
            <div className={style.menuItem}>
              <div className={style.menuIconAi}>
                <img
                  height={40}
                  style={{ borderRadius: '120px' }}
                  src={aboutGeneratorImg}
                  alt={'linkedin about generator icon'}
                />
              </div>
              <div>{t('LinkedinAboutSectionGenerator')}</div>
            </div>
          ),
          key: '4',
          onClick: () => {
            navigate('/linkedin-about');
          },
        },
      ]
    );
  }

  items.push({
    label: (
      <div className={style.menuItem}>
        <div className={style.menuIconAi}>
          <img
            height={40}
            style={{ borderRadius: '120px' }}
            src={linkedINPostGeneratorImg}
            alt={'linked post generator icon'}
          />
        </div>
        <div>{t('LinkedIn Post Generator')}</div>
      </div>
    ),
    key: '2',
    onClick: () => {
      navigate('/post-generator');
    },
  });
  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={['mail']}
      style={{
        lineHeight: '50px',
        minHeight: '50px',
        backgroundColor: ThemeColors.themeBackgroundColor,
        color: '#FFFFFF',
        position: 'fixed',
        width: '100%',
        border: '0px',
      }}
      id="header_navbar"
    >
      {isMobile && (
        <Button
          className={style.menuIcon}
          onClick={() => setIsMenuUnfolded((menuState: boolean) => !menuState)}
        >
          {isMenuUnfolded ? <MenuOutlined /> : <MenuUnfoldOutlined />}
        </Button>
      )}
      {!isMobile &&
        ((isInstituteCoach ||
          userInfo?.invites?.[selectedInstitute] === 'ACCEPTED') &&
        institute?.name ? (
          <div className={style.title}>
            <div className={style.imgDiv} style={{ marginLeft: '1rem' }}>
              <img src={institute?.logoUrl} height={24} alt="careerflow logo" />
            </div>
            <div className={style.instituteTitle}>{institute?.name}</div>
          </div>
        ) : (
          <div className={style.title}>
            <a href="https://careerflow.ai/app">
              <img
                src={logo}
                height={30}
                style={{
                  marginRight: '1rem',
                  marginBottom: '5px',
                  marginLeft: '1rem',
                }}
                alt="careerflow logo"
              />
            </a>
          </div>
        ))}

      <div className={style.profile_div}>
        {appConfig.features?.ai?.status === 'enabled' &&
          ((institute && !institute?.disabledFeatures?.includes('aiTools')) ||
            !selectedInstitute) && (
            <Dropdown
              className={style.dropdown}
              menu={{ items }}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                className="tertiary-btn"
                onClick={(e) => e.preventDefault()}
              >
                <Space>
                  <img src={starsIcon} alt="stars" />
                  {!isMobile && (
                    <span style={{ color: 'black' }}>{t('AITools')}</span>
                  )}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}

        {appConfig.features?.upgradeButton?.status === 'enabled' &&
          userInfo?.userType !== 'premium' && (
            <div>
              <Button
                onClick={() => setUpgrade(true)}
                className={'upgradePremiumButton'}
              >
                {isMobile ? t('Upgrade') : t('UpgradetoPremium')}
              </Button>
            </div>
          )}
        {appConfig.features?.ai?.status === 'enabled' &&
          userInfo?.userType === 'premium' && (
            <div className={'premiumButton'}>
              <img
                style={isMobile ? {} : { marginRight: '0.25rem' }}
                src={premiumStar}
                alt="star"
              />
              {isMobile ? '' : t('Premium')}
            </div>
          )}
        <ProfileInfo />
      </div>
    </Menu>
  );
};

export default Navbar;
