import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Tag, Typography } from 'antd';
import PDFGenerator from '../../../common/PdfGenerator';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import info from 'images/business.png';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const formatDate = (timestamp: number) => {
  return dayjs(timestamp).format("DD MMM 'YY [at] hh:mm a");
};

const AIToolHistoryCard = ({ historyObject, onViewDetails }: any) => {
  const responseContent =
    typeof historyObject.response === 'string'
      ? historyObject.response
      : historyObject.response?.content;

  const { t } = useTranslation();
  return (
    <div
      style={{
        border: '1px solid #E1EDFF',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        padding: '1rem',
      }}
    >
      {(historyObject.inputs?.targetJobTitle ||
        historyObject.inputs?.companyName) &&
        historyObject.promptType === 'cover-letter' && (
          <h3 style={{ display: 'flex', marginBottom: '0.5rem' }}>
            <img
              style={{ marginRight: '0.5rem' }}
              height={24}
              src={info}
              alt="info icon"
            />{' '}
            {historyObject.inputs?.targetJobTitle &&
            historyObject.inputs?.companyName
              ? `${historyObject.inputs.targetJobTitle} at ${historyObject.inputs.companyName}`
              : 'Role-specific cover letter as a ' +
                  historyObject.inputs?.targetJobTitle ||
                'Company specific cover letter for ' +
                  historyObject.inputs?.companyName ||
                'Generic Cover Letter'}
          </h3>
        )}

      <Typography.Paragraph style={{ fontSize: '12px' }} ellipsis={{ rows: 2 }}>
        {responseContent}
      </Typography.Paragraph>

      {historyObject.inputs?.tone && (
        <Tag style={{ marginBottom: '1rem' }} color="blue">
          {t('Tone')}: {historyObject.inputs?.tone}
        </Tag>
      )}
      {historyObject.inputs?.length && (
        <Tag style={{ marginBottom: '1rem' }} color="blue">
          {t('Length')}: {historyObject.inputs?.length}
        </Tag>
      )}

      {historyObject.inputs?.template && (
        <Tag style={{ marginBottom: '1rem' }} color="blue">
          {t('Template')}: {historyObject.inputs?.template?.title}
        </Tag>
      )}

      <Divider style={{ margin: '1rem 0 0.5rem 0' }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="sub-text-light">
          {t('Generatedon')} {formatDate(historyObject.createdAt)}
        </div>
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(`${responseContent}`);
              message.success(t('Copiedtoclipboard'));
            }}
            type="link"
          >
            {t('CopytoClipboard')}
          </Button>
          <PDFGenerator text={responseContent} historyObject={historyObject}>
            <Button
              onClick={() => analyticsEvent('Download Cover Letter PDF')}
              type="link"
            >
              {t('DownloadasPDF')} <DownloadOutlined />
            </Button>
          </PDFGenerator>
          <Button onClick={() => onViewDetails(historyObject)} type="link">
            {t('ViewDetails')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AIToolHistoryCard;
