import axios, { InternalAxiosRequestConfig } from 'axios';
import { config } from '../../utils/apiPaths';
import { auth } from '../../utils/firebase/firebaseIndex';

export const instituteClient = axios.create({
  baseURL: config.baseUrl + `/instituteApp`,
});

instituteClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    config.headers['sourceApp'] = `JOB_SEEKER`;
    config.headers['institute'] = localStorage.getItem('selectedInstitute');
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error: any) => Promise.reject(error)
);

const getInstituteFromWhiteLabeledUrl = (whiteLabeledUrl: string) => {
  return instituteClient.get(`/white-label/${whiteLabeledUrl}`);
};

const checkIfStudentInvited = (instituteId: string, studentId?: string) => {
  const url =
    `/institute/${instituteId}/verifyInstituteStudent` +
    (studentId ? `/${studentId}` : '');

  return instituteClient.get(url);
};

const verifyInstituteCoach = (instituteId: string, coachId?: string) => {
  const url =
    `/institute/${instituteId}/verifyInstituteCoach` +
    (coachId ? `/${coachId}` : '');

  return instituteClient.get(url);
};

const getInstituteById = (instituteId: string) => {
  return instituteClient.get(`/institute/${instituteId}`);
};

const instituteAppService = {
  getInstituteFromWhiteLabeledUrl,
  checkIfStudentInvited,
  verifyInstituteCoach,
  getInstituteById,
};

export default instituteAppService;
