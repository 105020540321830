import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { message } from 'antd';
import axios from 'axios';
import {
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  signOut,
  User,
} from 'firebase/auth';
import jwt_decode from 'jwt-decode';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useIdToken } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { INVITE_STATES } from '../components/layout/Navbar/Navbar';
import { CustomUserCredential } from '../models/CustomUserCredential';
import instituteAppService from '../services/api/InstituteService';
import {
  instituteAppApiPath,
  miscApiPath,
  stripeCheckoutApiPath,
  userDetailApiPath,
  userRoleApiPath,
} from '../utils/apiPaths';
import {
  auth,
  logInWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../utils/firebase/firebaseIndex';
import { isPublicRoute, isShouldLogout } from '../utils/publicRoutes';
import { t } from 'i18next';
import { sendMessage } from '../utils/chrome';
import { INSTITUTE_ROLES } from '../constants/instituteRoles';
import { FormItems } from '../pages/ResumeWalkthrough/types';

export type InitialState = {
  upgrade: boolean;
  setUpgrade: any;
  signUpOrSignInWithGoogleCall: (src: string) => void;
  userInfo: any;
  authToken: string | null;
  user?: any;
  newUser: boolean;
  setNewUser: any;
  isMobile: boolean;
  setUserInfo: any;
  whiteLabeledInstitute: string;
  showEmailVerificationModal: boolean;
  setShowEmailVerificationModal: any;
  newUserSignupComplete: boolean;
  loading: boolean;
  setIsExtensionInstalled: any;
  isInstituteCoach: boolean;
  isExtensionInstalled: boolean;
  appConfig: any;
  institute: any;
  handleAcceptInviteByLink: () => void;
  selectedInstitute: string;
  getUserDetails: () => void;
  signupWithEmailAndPassword: (
    email: string,
    password: string,
    fname: string,
    lname: string,
    src: string,
    setLoading: any,
    callback?: any,
    clearForm?: any
  ) => Promise<void> | null;
  signinWithEmailAndPassword: (
    email: string,
    password: string,
    cb?: any,
    clearForm?: any,
    setLoading?: any
  ) => Promise<void> | null;
  initInstitute: boolean;
};
export const AppContext = createContext<InitialState>({
  isExtensionInstalled: false,
  setIsExtensionInstalled: () => null,
  upgrade: false,
  setUpgrade: () => null,
  signUpOrSignInWithGoogleCall: () => null,
  userInfo: {},
  appConfig: {},
  authToken: null,
  user: {},
  isInstituteCoach: false,
  isMobile: false,
  newUser: false,
  setNewUser: () => null,
  setUserInfo: () => null,
  selectedInstitute: '',
  newUserSignupComplete: false,
  loading: false,
  handleAcceptInviteByLink: () => null,
  institute: null,
  getUserDetails: () => null,
  signinWithEmailAndPassword: null,
  whiteLabeledInstitute: '',
  showEmailVerificationModal: false,
  setShowEmailVerificationModal: () => null,
  signupWithEmailAndPassword: null,
  initInstitute: false,
});

interface Props {
  children: any;
}

export const sendTokenToChromeExtension = ({ user }: any) =>
  new Promise((resolve, reject) => {
    sendMessage(
      process.env.VITE_APP_EXTENSION_ID,
      { user },
      (response: any) => {
        const lastError = window.chrome?.runtime?.lastError;
        if (!response) {
          reject('Extension not installed');
        } else if (response && !response.success) {
          reject(lastError);
          return response;
        } else {
          resolve('Done');
        }
      }
    );
  });

function UserProvider({ children }: Props) {
  const [user, loading] = useIdToken(auth);
  const [isLoading, setIsLoading] = useState(true);
  const [upgrade, setUpgrade] = useState(false);
  const [institute, setInstitute] = useState<any>();
  const [initInstitute, setInitInstitute] = useState(false);
  const [isInstituteCoach, setIsInstituteCoach] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [newUserSignupComplete, setNewUserSignupComplete] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState('');
  const [authToken, setAuthToken] = useState(null);
  const [appConfig, setAppConfig] = useState({});
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [whiteLabeledInstitute, setWhiteLabeledInstitute] = useState('');
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);

  const [userInfo, setUserInfo] = useState<any>({
    fname: '',
    lname: '',
    email: '',
    username: '',
    profilePic: '',
    jobBoardOnboarding: null,
    questionnaire: {},
    removedSkills: [],
    addedSkills: [],
    phoneNo: '',
    gender: '',
    dob: '',
    invites: {},
    userType: '',
    country: '',
    webAppLanguage: '',
    userId: '',
  });

  useEffect(() => {
    if (window.location.pathname === '/walkthrough') {
      if (userInfo?.fname || userInfo?.lname) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(loading);
    }
  }, [loading, userInfo?.fname, userInfo?.lname]);

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const sendEmailVerificationRequest = useCallback(async (user: User) => {
    try {
      await sendEmailVerification(user);
      message.success(t('sentPleaseCheckEmail'));
    } catch (error) {
      console.error(error);
      if (error.toString().includes('too'))
        message.error(t('tooManyAttemptsTryAgain'));
      else
        message.error(
          'Error in sending verification email. Please try again later'
        );
    }
  }, []);

  useEffect(() => {
    let selectedInstitute = localStorage.getItem('selectedInstitute');
    if (!userInfo?.userDetailLoaded || !userInfo?.userRoleLoaded) return; // to check if we have loaded the user details or not
    if (
      !selectedInstitute ||
      !(
        userInfo?.invites?.[selectedInstitute] ||
        INSTITUTE_ROLES.includes(userInfo?.instituteRoles?.[selectedInstitute])
      )
    ) {
      const validInviteOrg = Object.keys(userInfo?.invites ?? {}).find(
        (key) => (userInfo?.invites || {})?.[key] !== INVITE_STATES.deactivated
      );
      if (validInviteOrg) {
        selectedInstitute = validInviteOrg;
      } else if (Object.keys(userInfo?.instituteRoles ?? {}).length) {
        selectedInstitute = Object.keys(userInfo?.instituteRoles)?.[0];
      } else if (userInfo?.userDetailLoaded && userInfo?.userType) {
        selectedInstitute = ``;
      }
    }
    if (selectedInstitute) {
      localStorage.setItem(
        'selectedInstitute',
        whiteLabeledInstitute ? whiteLabeledInstitute : selectedInstitute
      );
      setSelectedInstitute(selectedInstitute);
      instituteAppService
        .getInstituteById(selectedInstitute)
        .then((res: any) => {
          setInstitute({ id: selectedInstitute, ...res.data });
        })
        .catch((e) => {
          console.log('error', e);
        });

      if (
        INSTITUTE_ROLES.includes(userInfo?.instituteRoles?.[selectedInstitute])
      )
        setIsInstituteCoach(true);
    } else {
      localStorage.setItem('selectedInstitute', '');
    }
    setInitInstitute(true);
  }, [
    userInfo?.instituteRoles,
    userInfo?.invites,
    userInfo?.userId,
    userInfo?.userDetailLoaded,
    userInfo?.userType,
    userInfo?.userRoleLoaded,
    whiteLabeledInstitute,
  ]);

  useEffect(() => {
    if ('webAppLanguage' in userInfo) {
      i18n.changeLanguage(userInfo?.webAppLanguage);
    }
  }, [i18n, userInfo]);

  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) setIsMobile(true);
    else setIsMobile(false);
  }

  useEffect(() => {
    const siteHost = window.location.host;
    const instituteName = siteHost?.split('.')?.[1];

    const isWhiteLabeledInstitute =
      instituteName && instituteName !== 'careerflow';

    if (isWhiteLabeledInstitute) {
      instituteAppService
        .getInstituteFromWhiteLabeledUrl(siteHost)
        .then((res) => {
          const instituteId = res.data?.id;
          setInstitute(res.data);
          localStorage.setItem('selectedInstitute', instituteId);
          setSelectedInstitute(instituteId);
          setWhiteLabeledInstitute(instituteId);
        })
        .catch((e) => {
          console.log('error');
          console.log(e);
        });
      setInitInstitute(true);
    }

    axios.get(`${miscApiPath}/webappConfig`).then((res) => {
      if (res.data.content) setAppConfig(res.data.content);
    });
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const invitedBy = localStorage.getItem('invitedBy');
  const goToCheckout =
    localStorage.getItem('referalId') && localStorage.getItem('priceId');

  useEffect(() => {
    if (goToCheckout && userInfo.email) {
      message.success(
        'Please wait till we take you to subscription checkout page!'
      );
      axios
        .post(stripeCheckoutApiPath, {
          price: localStorage.getItem('priceId'),
          client_reference_id: localStorage.getItem('referalId') || undefined,
          success_url: 'https://careerflow.ai/thank-you-premium',
          coupon: localStorage.getItem('coupon') || undefined,
        })
        .then((res) => {
          window.location.assign(res.data.url);
          localStorage.removeItem('priceId');
        })
        .catch((e) => {
          message.error('There was an error in initiating the transaction');
          console.error(e);
        })
        .finally(() => {});
    }
  }, [goToCheckout, userInfo.email]);

  useEffect(() => {
    if (isPublicRoute()) return;

    if (
      (!user || (!user?.emailVerified && userInfo?.created > 1694802600000)) &&
      !loading &&
      !window.location.href.includes('/extension/login')
    ) {
      if (window.location.href.includes('/signup')) {
        return;
      }
      if (window.location.href.includes('/login')) {
        return;
      }
      if (window.location.href.includes('/resume-walkthrough')) {
        return;
      }
      navigate('/login');
    } else if (
      userInfo.userDetailLoaded &&
      !userInfo.initialOnboardingDone &&
      userInfo?.created > 1703010600000
    ) {
      if (window.location.pathname !== '/plan') navigate(`/walkthrough`);
    } else if (
      window.location.pathname === '/onboarding' &&
      (userInfo.jobBoardOnboarding === 10 ||
        userInfo.jobBoardOnboarding === true)
    ) {
      navigate('/');
    } else if (
      (window.location.pathname === '/login' ||
        window.location.pathname === '/signup' ||
        window.location.href.includes('/extension/login')) &&
      !!user &&
      (user?.emailVerified || userInfo?.created < 1694802600000)
    ) {
      sendTokenToChromeExtension({
        user,
      })
        .then((res) => {
          if (res) {
            console.log('res', res);
          }
          // message.success(
          //   'Careerflow chrome extension also logged in. Please feel free to close this window.'
          // );
          if (window.location.href.includes('/extension/login')) window.close();
        })
        .catch((e) => console.log(e));
      navigate('/');
    }
  }, [
    userInfo.jobBoardOnboarding,
    user,
    loading,
    userInfo?.created,
    navigate,
    userInfo,
  ]);

  useEffect(() => {
    if (!user && !loading && isShouldLogout()) logout();
  }, [loading, user]);

  useEffect(() => {
    const checkAndLogout = async () => {
      const email = user?.email;
      console.log('email', email);
      if (
        whiteLabeledInstitute &&
        institute?.allowOnlyInvitedSignups &&
        email
      ) {
        try {
          const studentDetails =
            await instituteAppService.checkIfStudentInvited(
              institute?.id,
              email
            );
          if (studentDetails?.data?.status === 'inactive') {
            if (!institute?.allowDeactivated) {
              throw new Error('Student inactive');
            } else return true;
          }
        } catch (e) {
          let isCoach = false;
          try {
            await instituteAppService.verifyInstituteCoach(
              institute?.id,
              email
            );
            isCoach = true;
          } catch (e) {
            console.error(e);
          }

          if (!isCoach) {
            message.error(
              `You do not have permission to log in or an unknown error occurred. Please contact ${institute?.name || institute?.id} admin.`
            );
            console.log('User does not have permission to the portal');
            return await signOut(auth);
          }
        }
      }
      console.log(whiteLabeledInstitute, 'hell');
    };
    if (whiteLabeledInstitute) {
      checkAndLogout();
    }
  }, [whiteLabeledInstitute, institute, user]);

  useEffect(() => {
    if (
      (!!user && newUser === false) ||
      (!!user && newUser && newUserSignupComplete)
    ) {
      try {
        const jwt = (auth.currentUser as any)?.stsTokenManager?.accessToken;
        const decodedToken: any = jwt_decode(jwt);
        axios.get(userRoleApiPath).then((userRoleGetResponse) => {
          localStorage.setItem(
            'signupSource',
            userRoleGetResponse.data.signupSource
          );
          setUserInfo((userInfo: any) => {
            return {
              ...userInfo,
              userRoleLoaded: true,
              username: `${
                userRoleGetResponse.data && userRoleGetResponse.data.fname
              } ${userRoleGetResponse.data && userRoleGetResponse.data.lname}`,
              fname: userRoleGetResponse.data && userRoleGetResponse.data.fname,
              lname: userRoleGetResponse.data && userRoleGetResponse.data.lname,
              email: userRoleGetResponse.data && userRoleGetResponse.data.email,
              userType:
                userRoleGetResponse.data.premiumUser ||
                decodedToken.userType === 'premium'
                  ? 'premium'
                  : 'basic',
              ...userRoleGetResponse.data,
              userId: userRoleGetResponse.data && userRoleGetResponse.data.id,
            };
          });
        });

        axios.get(userDetailApiPath).then((userDetailGetResponse) => {
          setUserInfo((userInfo: any) => {
            return {
              ...userInfo,
              userDetailLoaded: true,
              profilePic:
                userDetailGetResponse.data &&
                userDetailGetResponse.data[0] &&
                userDetailGetResponse.data[0].profilePhoto,
              jobBoardOnboarding:
                userDetailGetResponse.data &&
                userDetailGetResponse.data[0] &&
                userDetailGetResponse.data[0].jobBoardOnboarding,
              questionnaire:
                userDetailGetResponse.data &&
                userDetailGetResponse.data[0] &&
                userDetailGetResponse.data[0].questionnaire,
              phoneNo: userDetailGetResponse?.data[0]?.phoneNo,
              gender: userDetailGetResponse?.data[0]?.gender,
              dob: userDetailGetResponse?.data[0]?.dob,
              invites: userDetailGetResponse?.data[0]?.invites,
              removedSkills: userDetailGetResponse.data[0]?.removedSkills || [],
              addedSkills: userDetailGetResponse.data[0]?.addedSkills || [],
              country: userDetailGetResponse.data[0]?.country,
              webAppLanguage: userDetailGetResponse?.data[0]?.webAppLanguage,
              ...userDetailGetResponse.data?.[0],
            };
          });
        });
      } catch (err) {
        console.log('err :>> ', err);
      }
    }
  }, [newUser, newUserSignupComplete, user]);

  useEffect(() => {
    if (userInfo.jobBoardOnboarding === 10) {
      try {
        axios.get(userRoleApiPath).then((userRoleGetResponse) => {
          localStorage.setItem(
            'signupSource',
            userRoleGetResponse.data.signupSource
          );
          setUserInfo((userInfo: any) => {
            return {
              ...userInfo,
              userRoleLoaded: true,
              username: `${
                userRoleGetResponse.data && userRoleGetResponse.data.fname
              } ${userRoleGetResponse.data && userRoleGetResponse.data.lname}`,
              fname: userRoleGetResponse.data && userRoleGetResponse.data.fname,
              lname: userRoleGetResponse.data && userRoleGetResponse.data.lname,
              email: userRoleGetResponse.data && userRoleGetResponse.data.email,
              ...userRoleGetResponse.data,
              userId: userRoleGetResponse.data && userRoleGetResponse.data.id,
            };
          });
        });

        axios.get(userDetailApiPath).then((userDetailGetResponse) => {
          setUserInfo((userInfo: any) => {
            return {
              ...userInfo,
              userDetailLoaded: true,
              profilePic:
                userDetailGetResponse.data &&
                userDetailGetResponse.data[0] &&
                userDetailGetResponse.data[0].profilePhoto,
              jobBoardOnboarding:
                userDetailGetResponse.data &&
                userDetailGetResponse.data[0] &&
                userDetailGetResponse.data[0].jobBoardOnboarding,
              questionnaire:
                userDetailGetResponse.data &&
                userDetailGetResponse.data[0] &&
                userDetailGetResponse.data[0].questionnaire,
              phoneNo: userDetailGetResponse?.data[0]?.phoneNo,
              gender: userDetailGetResponse?.data[0]?.gender,
              dob: userDetailGetResponse?.data[0]?.dob,
              invites: userDetailGetResponse?.data[0]?.invites,
              country: userDetailGetResponse.data[0]?.country,
              webAppLanguage: userDetailGetResponse?.data[0]?.webAppLanguage,
              ...userDetailGetResponse.data?.[0],
            };
          });
        });
      } catch (err) {
        console.log('err :>> ', err);
      }
    }
  }, [userInfo.jobBoardOnboarding]);

  const getUserDetails = useCallback(() => {
    const jwt = (auth.currentUser as any)?.stsTokenManager?.accessToken;
    const decodedToken: any = jwt_decode(jwt);
    axios.get(userRoleApiPath).then((userRoleGetResponse) => {
      localStorage.setItem(
        'signupSource',
        userRoleGetResponse.data.signupSource
      );
      setUserInfo((userInfo: any) => {
        return {
          ...userInfo,
          userRoleLoaded: true,
          username: `${
            userRoleGetResponse.data && userRoleGetResponse.data.fname
          } ${userRoleGetResponse.data && userRoleGetResponse.data.lname}`,
          fname: userRoleGetResponse.data && userRoleGetResponse.data.fname,
          lname: userRoleGetResponse.data && userRoleGetResponse.data.lname,
          email: userRoleGetResponse.data && userRoleGetResponse.data.email,
          ...userRoleGetResponse.data,
          userId: userRoleGetResponse.data && userRoleGetResponse.data.id,
          userType:
            userRoleGetResponse.data.premiumUser ||
            decodedToken.userType === 'premium'
              ? 'premium'
              : 'basic',
        };
      });
    });

    axios.get(userDetailApiPath).then((userDetailGetResponse) => {
      setUserInfo((userInfo: any) => {
        return {
          ...userInfo,
          userDetailLoaded: true,
          profilePic:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].profilePhoto,
          jobBoardOnboarding:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].jobBoardOnboarding,
          questionnaire:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].questionnaire,
          phoneNo: userDetailGetResponse?.data[0]?.phoneNo,
          gender: userDetailGetResponse?.data[0]?.gender,
          dob: userDetailGetResponse?.data[0]?.dob,
          invites: userDetailGetResponse?.data[0]?.invites,
          country: userDetailGetResponse.data[0]?.country,
          webAppLanguage: userDetailGetResponse?.data[0]?.webAppLanguage,
          ...userDetailGetResponse.data?.[0],
        };
      });
    });
  }, []);

  const handleAcceptInviteByLink = useCallback(() => {
    const invitedBy = localStorage.getItem('invitedBy');

    if (invitedBy) {
      const payload = {
        firstName: userInfo.fname,
        lastName: userInfo.lname,
        email: userInfo.email,
        status: 'active',
      };
      axios
        .post(`${instituteAppApiPath}/${invitedBy}/linkInvite`, payload)
        .then(() => {
          localStorage.removeItem('invitedBy');
          getUserDetails();
        });
    }
  }, [getUserDetails, userInfo.email, userInfo.fname, userInfo.lname]);

  useEffect(() => {
    if (invitedBy && userInfo.email) {
      handleAcceptInviteByLink();
    }
  }, [handleAcceptInviteByLink, invitedBy, userInfo.email]);

  const handleResumeWalkthrough = async (resume: FormItems) => {
    try {
      const userDetailPayload = {
        fname: resume.firstName,
        lname: resume.lastName,
        phoneNo: resume.phone,
        dob: '',
        gender: '',
        country: '',
        experience: '',
        resumeUrl: '',
        resumeName: '',
      };

      await axios.put(userDetailApiPath, userDetailPayload);

      const userDetailGetResponse = await axios.get(userDetailApiPath);
      await axios.put(userDetailApiPath, {
        initialOnboardingDone: true,
      });
      setUserInfo((userInfo: any) => {
        return {
          ...userInfo,
          userDetailLoaded: true,
          profilePic:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].profilePhoto,
          jobBoardOnboarding:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].jobBoardOnboarding,
          questionnaire:
            userDetailGetResponse.data &&
            userDetailGetResponse.data[0] &&
            userDetailGetResponse.data[0].questionnaire,
          phoneNo: userDetailGetResponse?.data[0]?.phoneNo,
          gender: userDetailGetResponse?.data[0]?.gender,
          dob: userDetailGetResponse?.data[0]?.dob,
          invites: userDetailGetResponse?.data[0]?.invites,
          removedSkills: userDetailGetResponse.data[0]?.removedSkills || [],
          addedSkills: userDetailGetResponse.data[0]?.addedSkills || [],
          country: userDetailGetResponse.data[0]?.country,
          webAppLanguage: userDetailGetResponse?.data[0]?.webAppLanguage,
          ...userDetailGetResponse.data?.[0],
          initialOnboardingDone: true,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const signup = async (
    authResponse: CustomUserCredential,
    src: string = 'webapp'
  ) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const { displayName, email } = authResponse.user;
        const numberOfWords = displayName?.split(' ')?.length || 0;
        const firstname =
          numberOfWords > 1
            ? displayName?.split(' ')?.slice(0, -1)?.join(' ')
            : displayName || '';
        const lastname =
          numberOfWords > 1 ? displayName?.split(' ')?.slice(-1)?.[0] : '';

        const userRoleRequestBody = {
          role: 'MENTEE',
          fname: firstname, // get values from usestate object
          lname: lastname,
          mentorStatus: '',
          profilePhoto: authResponse.user.photoURL || '',
          linkedin: '',
          email,
          signupSource: src,
          mentorRating: 0,
        };

        const inviterOrg = localStorage.getItem('invitedBy');

        await axios.post(userRoleApiPath, {
          ...userRoleRequestBody,
          inviterOrg,
        });
        localStorage.removeItem('invitedBy');

        localStorage.setItem('token', authResponse._tokenResponse.idToken);
        resolve(true);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const signUpOrSignInWithGoogleCall = async (src: string = 'webapp') => {
    setNewUser(true);

    try {
      const authResponse: CustomUserCredential = await signInWithGoogle();

      // extension sign in
      const user = authResponse.user;

      const email = user.email;

      if (whiteLabeledInstitute && institute?.allowOnlyInvitedSignups) {
        try {
          const studentDetails =
            await instituteAppService.checkIfStudentInvited(
              institute?.id,
              email
            );
          if (studentDetails?.data?.status === 'inactive') {
            if (!institute?.allowDeactivated) {
              throw new Error('Student inactive');
            } else return true;
          }
        } catch (e) {
          let isCoach = false;
          try {
            await instituteAppService.verifyInstituteCoach(
              institute?.id,
              email
            );
            isCoach = true;
          } catch (e) {
            console.error(e);
          }

          if (!isCoach) {
            message.error(
              `You do not have permission to log in or an unknown error occurred. Please contact ${institute?.name || institute?.id} admin.`
            );
            return await signOut(auth);
          }
        }
      }

      const { isNewUser, idToken } = authResponse._tokenResponse;

      setAuthToken(idToken);

      if (isNewUser) {
        setNewUser(true);
        analyticsEvent('Sign up with google', 'Login', {
          userEmail: email,
        });
        await signup(authResponse, src);
        setNewUserSignupComplete(true);
        getUserDetails();
      } else {
        analyticsEvent('Sign In with google', 'Login', {
          userEmail: email,
        });
        setNewUser(false);
      }

      localStorage.setItem('token', authResponse._tokenResponse.idToken);

      sendTokenToChromeExtension({
        jwt: idToken,
        user: user,
      })
        .then(() => {
          message.success('Careerflow chrome extension also logged in.');
          // if (!isNewUser) window.close(); // Don't close window if the new user has signed up
        })
        .catch((err) => console.log(err));

      if (newUser) {
        const resume = localStorage.getItem('resume-walkthrough-resume');
        const resumeData: FormItems = resume ? JSON.parse(resume) : null;
        if (resumeData) {
          analyticsEvent(
            'Resume Walkthrough - Sign Up with Google',
            'Resume Walkthrough'
          );
          await handleResumeWalkthrough(resumeData);
          analyticsEvent(
            'Resume Walkthrough - Created Resume',
            'Resume Walkthrough'
          );
          navigate('/resumebuilder/editor?type=resume');
          localStorage.removeItem('resume-walkthrough-resume');
        }
        return;
      }
      getUserDetails();
      const resume = localStorage.getItem('resume-walkthrough-resume');
      const resumeData: FormItems = resume ? JSON.parse(resume) : null;
      if (resumeData) {
        analyticsEvent(
          'Resume Walkthrough - Sign In with Google',
          'Resume Walkthrough'
        );
        await handleResumeWalkthrough(resumeData);
        analyticsEvent(
          'Resume Walkthrough - Created Resume',
          'Resume Walkthrough'
        );
        navigate('/resumebuilder/editor?type=resume');
        localStorage.removeItem('resume-walkthrough-resume');
      }
    } catch (error) {
      console.log;
    }
  };

  const signupWithEmailAndPassword = useCallback(
    async (
      email: string,
      password: string,
      fname: string,
      lname: string,
      src: string,
      setLoading: any,
      callback?: any,
      clearForm?: any
    ) => {
      try {
        setNewUser(true);
        const authResponse = await registerWithEmailAndPassword(
          email,
          password
        );
        analyticsEvent('Sign up with email and password', 'Login', {
          userEmail: email,
        });
        const userRoleRequestBody = {
          role: 'MENTEE',
          fname: fname, // get values from usestate object
          lname: lname,
          mentorStatus: '',
          profilePhoto: '',
          linkedin: '',
          signupSource: src,
          email,
          mentorRating: 0,
        };
        const inviterOrg = localStorage.getItem('invitedBy');

        await axios.post(userRoleApiPath, {
          ...userRoleRequestBody,
          inviterOrg,
        });
        localStorage.removeItem('invitedBy');
        setNewUserSignupComplete(true);

        getUserDetails();

        if (!authResponse.user?.emailVerified) {
          await sendEmailVerificationRequest(authResponse.user);
          callback && callback();
        }
        clearForm && clearForm();
      } catch (error: any) {
        message.error(`${error}`.replace('FirebaseError: Firebase:', ''));
        navigate('/login');
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const signinWithEmailAndPassword = async (
    email: string,
    password: string,
    cb?: any,
    clearForm?: any,
    setLoading?: any
  ) => {
    try {
      const result = await fetchSignInMethodsForEmail(auth, email);
      analyticsEvent('Sign in with email and password', 'Login', {
        userEmail: email,
      });
      // Handle case where user already signed in with Google previously, so they have auth but no password.
      // If we don't do this, user will receive the "user not found" error message which is misleading.
      if (result.includes('google.com') && !result.includes('password')) {
        message.error(
          'You previously signed in using "Continue With Google". To sign in via email, please create a password using the "Forgot Password" option.'
        );
        return;
      }
      const authResponse = await logInWithEmailAndPassword(email, password);

      if (
        !authResponse.user?.emailVerified &&
        userInfo?.created > 1694802600000
      ) {
        await sendEmailVerificationRequest(authResponse.user);
        cb();
      }
      if (authResponse.user?.emailVerified || userInfo?.created < 1694802600000)
        sendTokenToChromeExtension({
          user: authResponse.user,
        })
          .then(() => {
            message.success('Careerflow chrome extension also logged in.');
            if (window.location.href.includes('/extension/login')) {
              window.close();
            }
          })
          .catch((err) => console.log(err));
      const token = await authResponse.user.getIdToken();
      localStorage.setItem('token', token);
      if (authResponse.user?.emailVerified) {
        clearForm && clearForm();
      }
      const resume = localStorage.getItem('resume-walkthrough-resume');
      const resumeData: FormItems = resume ? JSON.parse(resume) : null;
      if (resumeData) {
        analyticsEvent(
          'Resume Walkthrough - Sign In with Email',
          'Resume Walkthrough'
        );
        await handleResumeWalkthrough(resumeData);
        analyticsEvent(
          'Resume Walkthrough - Created Resume',
          'Resume Walkthrough'
        );
        navigate('/resumebuilder/editor?type=resume');
        localStorage.removeItem('resume-walkthrough-resume');
      }
    } catch (error: any) {
      console.log(`${error}`);
      if (`${error}`.includes('user-not-found')) {
        message.error(
          'User not found! Please use the Sign Up option to create a new account.'
        );
      } else {
        message.error(`${error}`.replace('FirebaseError: Firebase:', ''));
      }
    } finally {
      setLoading && setLoading(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        upgrade,
        selectedInstitute,
        user,
        setUpgrade,
        signUpOrSignInWithGoogleCall,
        userInfo,
        authToken,
        newUser,
        appConfig,
        newUserSignupComplete,
        institute,
        setNewUser,
        isMobile,
        showEmailVerificationModal,
        setShowEmailVerificationModal,
        setUserInfo,
        isInstituteCoach,
        isExtensionInstalled,
        setIsExtensionInstalled,
        loading: isLoading,
        handleAcceptInviteByLink,
        getUserDetails,
        signupWithEmailAndPassword,
        signinWithEmailAndPassword,
        whiteLabeledInstitute,
        initInstitute,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default UserProvider;
