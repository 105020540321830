export const isPublicRoute = () => {
  if (window.location.pathname.match('/certificate/*')) return true;
  // if (window.location.pathname.match('/extension/*')) return true;
  if (window.location.pathname.match('/logout')) return true;
  if (window.location.pathname.match('/thanks')) return true;
  if (window.location.pathname.match('/plan')) return true;
  if (window.location.pathname.match('/onboarding-questions')) return true;
  if (window.location.pathname.match('/resume-walkthrough')) return true;
  return false;
};

export const isShouldLogout = () => {
  if (window.location.pathname.match('/certificate/*')) return false;
  if (window.location.pathname.match('/login')) return false;
  if (window.location.pathname.match('/signup')) return false;
  if (window.location.pathname.match('/resume-walkthrough')) return false;
  return true;
};
