import axios, { InternalAxiosRequestConfig } from 'axios';
import { auth } from '../../utils/firebase/firebaseIndex';
import { faangpathApiPath } from '../../utils/apiPaths';

export const faangpathClient = axios.create({
  baseURL: faangpathApiPath,
});

faangpathClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    config.headers['sourceApp'] = `JOB_SEEKER`;
    config.headers['institute'] = localStorage.getItem('selectedInstitute');
    if (config && config.headers && auth.currentUser) {
      const accessToken = await auth.currentUser.getIdToken();
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error: any) => Promise.reject(error)
);

const postResumeParsingErrorReporting = (errorData: any) => {
  return faangpathClient.post('/error/resumeParser', errorData);
};

const deleteAccount = () => {
  return faangpathClient.delete('/delete-account');
};

const redeemVoucherCFPremium = (code: string) => {
  return faangpathClient.get(`/redeem/${code}`);
};

const faangpathService = {
  postResumeParsingErrorReporting,
  redeemVoucherCFPremium,
  deleteAccount,
};

export default faangpathService;
