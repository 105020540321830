import React from 'react';
import styles from './MainLoader.module.scss';
import logo from 'images/logo.png';

function MainLoader() {
  const isCFPage = window.location.host?.split('.')?.[1] === 'careerflow';
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '1.5rem',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          fontSize: '30px',
          fontWeight: '500',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isCFPage && (
          <>
            <img src={logo} alt="careerflow logo" height={30} />
            <div>Careerflow</div>
          </>
        )}
      </div>
      <div className={styles.loaderContainer}>
        <div className={styles.progressBar}></div>
      </div>
    </div>
  );
}

export default MainLoader;
